<template>
  <div class="container flex flex-column flex-around">
    <div class="navigation flex flex-row flex-between">
      <div class="navigation-title flex flex-row flex-middle">
        <img src="@/assets/images/logo.png" alt="benma" />
        <span class="navigation-title-text">广州笨马科技有限公司</span>
      </div>
      <div class="navigation-menu flex-item">
        <!-- <ul class="flex flex-row flex-right">
          <router-link to="/home"><li>首页</li></router-link>
          <router-link to="/case"><li>成功案例</li></router-link>
          <router-link to="/about"> <li>关于我们</li></router-link>
        </ul> -->
        <ul class="flex flex-row flex-right">
          <li
            v-for="(item, key) in list"
            :key="key + item.title"
            :class="{ highlight: item.active === active }"
            @click="tabarBtn(item)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
    <div class="info flex flex-column">
      <div class="footer flex flex-row flex-between flex-middle">
        <div class="footer-info flex flex-column flex-between">
          <div>
            <h2>欢迎联系我们</h2>
            <p>您是否有相关的项目需求，或者您对我们的业务有兴趣？期待您联系我们</p>
          </div>
          <div>
            <p>电话：13760641509</p>
            <p>邮箱：gump@benma.tech</p>
            <p>地址：广州市天河区建华路23号103东侧之一笨马科技</p>
          </div>
        </div>
        <div class="footer-img flex flex-column flex-center">
          <img src="@/assets/images/QRcode.png" alt="QRcode" />
          <p>扫描二维码 关注笨马公众号</p>
        </div>
      </div>
      <div class="version">
        <span>Copyright © 广州笨马科技有限公司 All Rights Reserved </span>
        <a target="_blank" href="http://www.beian.miit.gov.cn">粤ICP备18113977号-3</a>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script src="https://cdn.bootcdn.net/ajax/libs/vue/2.6.11/vue.js"></script>

<script>
import request from "@/util/request";

export default {
  data() {
    return {
      currentPath: "/home",
      list: [
        { title: "首页", path: "/home", active: 0 },
        { title: "成功案例", path: "/case", active: 1 },
        { title: "关于我们", path: "/about", active: 2 },
      ],
      active: 0,
    };
  },

  watch: {
    $route(to, from) {
      this.currentPath = this.$route.path;
    },
  },

  created: function() {
    this.currentPath = this.$route.path;
  },

  mounted: function() {},
  // 方法
  methods: {
    tabarBtn(item) {
      this.$router.push(item.path);
      this.active = item.active;
    },
  },
};
</script>

<style lang="less">
@import "../page/common.less";
</style>
