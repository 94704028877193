import axios from "axios";
import qs from "qs";
// import { Toast } from "vant";
import urls from "@/api/urls";
import config from "@/config";

// 控制台打印模拟数据
import { logMockAxios } from "../../mock/core/logMock";
// 引入mock数据
if (__MOCK__) {
  require("../../mock");
}

const instance = axios.create({
  baseURL: config.apiPrefix,
  withCredentials: true,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
  transformRequest: [
    function(data) {
      let ret = "";
      if (data) {
        ret = qs.stringify(data);
        return ret;
      }
    },
  ],
});

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
instance.interceptors.response.use(
  (response) => {
    // 控制台打印模拟数据
    logMockAxios(response);

    const data = response.data;
    // TODO 统一错误码
    if (data.code === 3) {
      // TODO 由前端记录登录后需要跳转的页面路径
      if (data.data !== null) {
        location.href = data.data;
        return;
      }
    }
    if (typeof data === "object" && data.code !== 0) {
      if (data.code === 11 || data.code === 12) {
        return data;
      } else {
        // Toast(data.errorMsg);
      }
    }
    return data;
  },
  (error) => {
    // Toast("网络请求失败，请重试");
    return Promise.reject(error);
  }
);

export default {
  get: (url, data) => {
    return instance.get(url, data ? { params: data } : {});
  },
  post: (url, data) => {
    return instance.post(url, data);
  },
  put: (url, data) => {
    return instance.put(url, data);
  },
  delete: (url, data) => {
    return instance.delete(url, data ? { params: data } : {});
  },
  patch: (url, data) => {
    return instance.patch(url, data);
  },
  upload: function(param) {
    return new Promise((resolve, reject) => {
      axios
        .post(urls.upload, param, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          resolve(response.data);
        });
    });
  },
};
