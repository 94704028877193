import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "./locale";

// 文档 https://kazupon.github.io/vue-i18n/zh/guide/formatting.html

// 默认locale
const LOCALE_DEFAULT = "zh-CN";

// 当地locale
const localLocale = () => {
  // 从缓存获取
  return localStorage.locale;
};

Vue.use(VueI18n);
const i18n = new VueI18n({
  // 支持动态修改locale切换不同语言
  locale: localLocale() || LOCALE_DEFAULT,
  fallbackLocale: LOCALE_DEFAULT,
  messages: messages,
});

export default i18n;
