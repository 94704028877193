<template>
  <div class="container">
    <div class="header module flex flex-between flex-middle">
      <div class="header-content">
        <div>互联网 + 软件服务商</div>
        <div>小程序+APP/社区电商/视频直播/仓库管理/大数据可视化</div>
        <div>
          <button>联系我们</button>
        </div>
      </div>
      <div class="header-img">
        <img src="@/assets/images/interweb.png" alt="interweb" />
      </div>
    </div>
    <div class="main felx-item flex-center flex-middle flex-column flex">
      <div class="main-one main-gap flex-middle flex-column flex">
        <span>解决方案</span>
        <p>
          广州笨马科技有限公司以专业的态度提供一站式产品服务技术，包括小程序+APP/社区电商/视频直播/仓库管理/大数据可视化
        </p>
        <div class="content flex flex-row ">
          <div class="content-card flex flex-column flex-middle">
            <img src="@/assets/images/EC.png" alt="EC" />
            <h2>小程序分销商城、社区电商</h2>
            <p>
              广州笨马科技有限公司以专业的态度提供一站式产品服务技术，包括小程序+APP/社区电商/视频直播/仓库管理/大数据可视化
            </p>
            <router-link to="/solution">
              <button>查看更多</button>
            </router-link>
          </div>
          <div class="content-card flex flex-column flex-middle">
            <img src="@/assets/images/storehouse.png" alt="storehouse" />
            <h2>电商仓库管理</h2>
            <p>
              广州笨马科技有限公司以专业的态度提供一站式产品服务技术，包括小程序+APP/社区电商/视频直播/仓库管理/大数据可视化
            </p>
            <router-link to="/solution">
              <button>查看更多</button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="main-two main-gap flex flex-column flex-middle">
        <span>成功案例</span>
        <p>
          广州笨马科技有限公司以专业的态度提供一站式产品服务技术，包括小程序+APP/社区电商/视频直播/仓库管理/大数据可视化
        </p>
        <div class="content flex flex-middle">
          <div class="one flex flex-column flex-middle">
            <img src="@/assets/images/chaohui.png" alt="潮荟" />
            <p>潮荟服装平台</p>
          </div>
          <div class="two flex flex-column flex-middle">
            <img src="@/assets/images/weiyan.png" alt="微言教育" />
            <p>微言教育（小程序）</p>
          </div>
          <div class="three flex flex-column flex-middle">
            <img src="@/assets/images/lexiang.png" alt="乐享小鹿" />
            <p>乐享小鹿幼儿社区平台（公众号）</p>
          </div>
        </div>
        <router-link to="/case">
          <button>查看更多</button>
        </router-link>
        <!-- <button>查看更多</button> -->
        <!-- <router-link to="/case" class="link flex flex-middle flex-center">
          <div>
            查看更多
          </div>
        </router-link> -->
      </div>
      <div class="main-three main-gap flex flex-column flex-middle">
        <span>合作伙伴</span>
        <p>
          专业对待每一个客户，助力创造更多价值
        </p>
        <div class="content flex flex-middle">
          <div>
            <img src="@/assets/images/China Mobile.png" alt="QRcode" />
          </div>
          <div>
            <img src="@/assets/images/oppo.png" alt="QRcode" />
          </div>
          <div>
            <img src="@/assets/images/fengshi.png" alt="QRcode" />
          </div>
          <div>
            <img src="@/assets/images/hanxin.png" alt="QRcode" />
          </div>
          <div>
            <img src="@/assets/images/youmi.png" alt="QRcode" />
          </div>
        </div>
        <div class="blank"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  created: function() {},

  mounted: function() {},

  methods: {},
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
