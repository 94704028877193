import qs from "qs";

/**
 * @param {*} response
 */
export const logMockAxios = (response) => {
  // 控制台打印模拟数据
  if (response.data.__mock) {
    const url = `${response.config.url}?${qs.stringify(response.config.params)}`;
    console.log(`mock -- "${url} ${response.config.method.toUpperCase()}"`, response);
  }
  return response;
};
