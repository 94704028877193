import Vue from "vue";
import VueRouter from "vue-router";

// 1.为了达到路由懒加载的效果, 引用组件需要使用以下方式
// const Other = () => import("./other/index/index.vue");
// 2.首屏需要加载的组件直接引入
// import Home from "./home/index.vue";

import Home from "./home/index.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      component: Home,
      meta: { title: "笨马科技" },
    },
    {
      path: "/about",
      component: () => import("./about/index.vue"),
      meta: { title: "关于我们" },
    },
    {
      path: "/case",
      component: () => import("./case/index.vue"),
      meta: { title: "成功案例" },
    },
    // {
    //   path: "/solution",
    //   component: () => import("./solution/index.vue"),
    //   meta: { title: "解决方案" },
    // },
  ],
});

router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  if (to.meta.content) {
    const head = document.getElementsByTagName("head");
    const meta = document.createElement("meta");
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router;
