import Vue from "vue";
import { Loading, Rate, Popup, DatetimePicker, Uploader, ImagePreview, Dialog, Swipe, SwipeItem, Lazyload } from "vant";
import VueWechatTitle from "vue-wechat-title";
import App from "@/component/App.vue";
import BaiduMap from "vue-baidu-map";
import router from "@/component/route";
import i18n from "@/i18n/i18n";

// test mock
// import request from "@/util/request";
// request.get(`/member/123`, { name: "fm" }).then((res) => {});
// request.get(`/member/list`).then((res) => {});

Vue.config.productionTip = false;
Vue.use(Loading)
  .use(Popup)
  .use(DatetimePicker)
  .use(VueWechatTitle)
  .use(Rate)
  .use(Uploader)
  .use(ImagePreview)
  .use(Dialog)
  .use(Swipe)
  .use(SwipeItem)
  .use(Lazyload)
  .use(BaiduMap, {
    ak: "yrETSCsmGEgPtUOil50jeHi4y19NrLLX", // 这个地方是官方提供的ak密钥
  });

new Vue({
  i18n,
  router: router,
  render: (h) => h(App),
}).$mount("#app");
